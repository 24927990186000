<template>
  <div class="edu-frame">
    <edu-nav-bar title="学习天地" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <van-list
          class="activity-list"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="class-list" v-for="(item,key) in list" :key="key" @click="toDetail(item)">
          <div style="flex-direction: row;display: flex;background-color: white;padding: 10px 8px;align-items: center;">
            <div class="class-detail">
              <div class="class-detail-title">标题：{{ item.learningTitle }}</div>
              <div class="class-detail-more">说明：{{ item.note }}</div>
              <div class="class-detail-more">创建日期：{{ item.createtime | filterDate }}</div>
            </div>
            <van-icon name="arrow" color="lightgray" size="24px"/>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import {Button, Tag, List, Icon} from "vant";
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import ActivityApi from "@/api/ActivityApi";
import XEUtils from "xe-utils";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanTag: Tag,
    VanList: List,
    VanIcon: Icon
    /*VanCheckbox: Checkbox*/
  },
  filters: {
    filterDate(date) {
      return date != null && date != '' ? XEUtils.toDateString(XEUtils.toStringDate(date, 'yyyyMMddHHmmss'), 'yyyy-MM-dd HH:mm') : ''
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      list: []
    }
  },
  methods: {
    toDetail(item) {
      this.$router.push({ name: 'vxxtd', query: { learningId: item.learningId } })
    },
    onLoad() {
      this.loading = true
      this.pageInfo.condition.isStop = 0
      ActivityApi.getLearningWorlds(this.pageInfo).then(response => {
        this.loading = false
        console.log(response)
        for(var i = 0; i < response.res.list.length; i++) {
          this.list.push(response.res.list[i])
        }
        if (response.res.isLastPage == 1) {
          this.finished = true
        } else {
          this.pageInfo.page = this.pageInfo.page+1
        }
      }).catch(() => { this.loading = false })
    }
  },
  mounted() {
    this.onLoad()
  }
}
</script>
<style scoped>
.class-list {
  width: 95%;
  border-radius: 8px;
  border: 1px solid lightgrey;
  box-shadow: 1px 2px 2px #ddd;
  margin: 10px auto;
  overflow: hidden;;
}
.class-item {
  background-color: white;
}
.title-tips {
  margin-left: 2%;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: black;
}

.class-split {
  width: 95%;
  height: 1px;
  border-top: 2px dotted lightgrey;
}
.pop-btn {
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #1989fa;
  padding: 3px 8px;
  font-size: 14px;
  color: white;
  border-left: 2px solid lightblue;
  border-top: 2px solid lightblue;
  border-bottom: 2px solid lightblue;
  z-index: 999;
}

.content-frame {
  height: calc(100vh - 46px);
}

.activity-list {
  width: 100%;
  overflow: auto;
}
.class-detail-title {
  margin: 1px auto;
}
</style>
